<template>
  <GoogleMapLoader :mapConfig="mapConfig" >
    <template slot-scope="{ google, map }">
      <GoogleMapMarker
        v-for="marker in markers"
        :key="marker.id"
        :marker="marker"
        :google="google"
        :map="map"
      />
    </template>
  </GoogleMapLoader>
</template>

<script>
import { mapSettings } from "@/components/dashboard/map/constants/mapSettings";

export default {
  name:"mapView",
  components: {
    
    GoogleMapLoader : ()=>import("@/components/dashboard/map/GoogleMapLoader"),
    GoogleMapMarker : () =>import("@/components/dashboard/map/GoogleMapMarker")
  },

  data() {
    return {
      markers: [
        {
          id: "a",
          title:"Marker 1",
          price:"962,500.00",
          position: { lat: 41.015137, lng: 28.979530 },
        },
        {
          id: "b",
          title:"Marker 2",
          price:"962,500.00",
          position: { lat: 39.925533, lng: 32.866287 },
        },
        {
          id: "c",
          title:"Marker 3",
          price:"962,500.00",
          position: { lat: 38.734802, lng: 35.467987 },
        },
      ],
    };
  },
  
   computed: {
    
    mapConfig() {
      return {
        ...mapSettings,
        center: this.mapCenter,
      };
    },
    mapCenter() {
      return this.markers[1].position;
    },
  },
};
</script>
